<template>
    <div class="container">
        <div class="d-flex justify-content-center align-items-center h-100" v-if="isLoading">
            <b-spinner variant="success" label="Spinning"></b-spinner>
        </div>
        
        <div class="top-div" v-if="!isLoading">
            <div class="balance-withdrawal">
                <div class="balance-token">
                    <div><img :src="balance.token_image" width=70 height=70 /></div>
                    <div class="d-flex flex-column pl-4">
                        <div class="balance-token-text1"><strong>{{ balance.token }}</strong></div>
                        <div class="balance-token-text2">{{ symbol }}</div>
                    </div>
                </div>

                <div class="deposit-balance">
                    <div class="d-flex flex-column">
                        <div class="balance-title">{{$t("Total Balance")}}</div>
                        <div class="balance-content">${{(balance.balance == null ? 0 : addCommas(balance.balance))}}</div>
                    </div>

                    <div class="d-flex flex-column">
                        <div class="balance-title">{{$t("Withdrawal Balance")}}</div>
                        <div class="balance-content">${{balance.withdrawable == null ? 0 : addCommas(balance.withdrawable) }}</div>
                    </div>
                </div>
            </div>

            <div class="bal-button">
                <b-button class="bal-btn" @click="showSendModal">
                    <img :src="require('../assets/icon/send.svg')" />
                    <div>{{$t("Send")}}</div>
                </b-button>

                <b-button class="bal-btn" @click="showReceiveModal">
                    <img :src="require('../assets/icon/receive.svg')" />
                    <div>{{$t("Request Deposit")}}</div>
                </b-button>

                <button class="bal-btn" @click="routeToTransaction">
                    <inline-svg
                        :src="require('../assets/icon/swap.svg')"
                        width="45"
                        height="35"
                        aria-label="swap"
                        id="swap"
                    ></inline-svg>

                    <div>{{$t("Transaction")}}</div>
                </button>
            </div>
        </div>

        <!-- Payment address -->
        <div v-if="!isLoading && request_deposit_histories.length > 0">
            <table class="table table-borderless mt-3 table-responsive" id="child-add-table"> 
                <thead>
                    <th>{{$t("DATE")}}</th>   
                    <th>{{$t("ID")}}</th>          
                    <th>{{$t("ADDRESS")}}</th>
                    <th>{{$t("AMOUNT")}}</th>
                    <th>{{$t("STATUS")}}</th>
                </thead>

                <tbody v-for="(row, index) in request_deposit_histories" v-bind:key="index">
                    <tr role="button" @click="goToRequestDepositDetails(row.deposit_id)">
                        <td>{{convertDate(row.start)}}</td>
                        <td>{{row.deposit_id}}</td>
                        <td>
                            <div class="d-flex justify-content-center align-items-center text-center">
                                <div class="address">
                                    {{row.to?.address}}
                                </div>
                                <div class="tooltip__layout">
                                    <button class="icon-btn" @click.prevent.stop="copyToClip(row.to?.address, `child` + row.deposit_id)">
                                        <inline-svg
                                            :src="require('../assets/icon/copy.svg')"
                                            width="18"
                                            aria-label="copy"
                                        ></inline-svg>
                                        <span class="tooltip__content" :id="'child' + row.deposit_id">{{$t("Copied!")}}</span>
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td>{{row.amount}}</td>
                        <td>{{row.status}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center">
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                ></b-pagination>
            </div>
        </div>
        
        <!-- Send Modal -->
        <b-modal ref="send-modal" hide-footer :title="'SEND ' + symbol" centered>
            <div class="d-flex flex-column text-center">
                <form id="send-modal-form" class="modal-form" @submit.stop.prevent="sendToken"> 
                    <div class="form-group">
                        <b-form-input
                            id="example-input-1"
                            :placeholder="$t(`Enter to address`)"
                            v-model="$v.sendTokenForm.to_address.$model"
                            :state="validateState('to_address','sendTokenForm')"
                            aria-describedby="input-1-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
                    </div>     
                    <div class="form-group">
                        <b-form-input
                            id="example-input-1"
                            :placeholder="$t(`Enter amount`)"
                            v-model="$v.sendTokenForm.amount.$model"
                            :state="validateState('amount','sendTokenForm')"
                            aria-describedby="input-1-live-feedback"
                            ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
                    </div>
                    <div class="form-group">
                        <div class="mt-5 mb-5 w-100">
                            <button type="submit" class="btn submit-btn" :disabled="$v.sendTokenForm.amount.$model > balance.balance">{{$t("Send")}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </b-modal>

        <!-- Receive Modal -->
        <b-modal ref="request-deposit-modal" hide-footer :title="'REQUEST DEPOSIT '+ symbol" centered>
            <div class="d-flex flex-column text-center">
                <form id="request-deposit-form" class="modal-form" @submit.stop.prevent="requestDeposit">      
                    <div class="form-group">
                        <div class="form-group">
                            <b-form-input
                            id="example-input-1"
                            :placeholder="$t(`Enter amount`)"
                            v-model="$v.requestDepositForm.amount.$model"
                            :state="validateState('amount','requestDepositForm')"
                            aria-describedby="input-1-live-feedback"
                            type="number"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="mt-5 mb-5 w-100">
                            <button type="submit" class="submit-btn">{{$t("Send")}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import api from '../helper/api.js';
 
import InlineSvg from "vue-inline-svg";
import moment from "moment";
// import VueQr from 'vue-qr'

export default ({
    name: "ReuseBalance",
    components: {
        InlineSvg,
        // VueQr
    },    
    data() {
        return {
            isLoading: false,
            symbol : this.$route.params.symbol,   
            addChildForm: {
                label:""
            },
            token_wallet: [],
            sendTokenForm : {
                to_address: "",
                amount: ""
            },
            requestDepositForm : {
                amount:""
            },
            balance: {
                balance: null,
                nettworth: null,
                token: null,
                token_image: null,
                withdrawable: null
            },
            receiveModal_address: null,
            request_deposit_histories:[
                {
                    'id':1,
                    'date': 1663769290000,
                    'address':"TQbdxKkWpqBJ1tKQBBqUowqK9fxygWSY4s",
                    'amount':100,
                    'status':'processing'
                },
                {
                    'id':2,
                    'date': 1665769290000,
                    'address':"TQbdxKkWpqBJ1tKQBBqUowqK9fxygWSY4s",
                    'amount':100,
                    'status':'processing'
                }
            ],
            currentPage:1,
            rows:0,
            perPage:10,
        }
    },
    watch:{
        currentPage(){
            this.fetchRequestDeposits();
        }
    },
    validations: {
        addChildForm: {
            label: {
                required,
            },
        },
        sendTokenForm: {
            to_address: {
                required
            },
            amount: {
                required,
                Number
            }
        },
        requestDepositForm: {
            amount: {
                required 
            }
        }
    },
    async mounted() {
        this.isLoading = true;
        // await this.fetchAddress();
        await this.fetchBalances();
        await this.fetchRequestDeposits();
        this.isLoading = false;
    },

    methods: {
        validateState(name,form) {
            const { $dirty, $error } = this.$v[form][name];
            return $dirty ? !$error : null;
        },

        async copyToClip(address, id) {
            await this.$copyText(address).then(function () {
                var x = document.getElementById(id);
                x.classList.add("show");
                setTimeout(function () {
                    x.classList.remove("show");
                }, 1000);
            })
        },

        routeToTransaction() {
            this.$router.push('/transaction')
        },

        addCommas (nStr) {
            nStr += ''
            var x = nStr.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return x1 + x2
        },

        async addChildAddress(){
            this.$v.addChildForm.$touch();
            if (this.$v.addChildForm.$anyError) {
                return;
            }

            const params = {
                "api_symbol":this.$route.params.symbol,
                "label":this.addChildForm.label
            }
            
            try{
                const response = await api.apiCall('trx','POST','/addresses/create',params);

                if((response.status).toUpperCase() == "SUCCESS") {
                    this.$swal({
                        text: this.$t("Child address added successfully"),
                        icon: 'success',
                        confirmButtonColor: '#33AD4A',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                } else {
                    this.$swal({
                        text: this.$t("Child address already added"),
                        icon: 'error',
                        confirmButtonColor: '#33AD4A',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$refs['add-child-modal'].hide()
                        }
                    })
                }
            }catch(error){
                this.$swal('Error',error.message,'error');
                console.error(error);
            }

        },

        async sendToken() {
            this.$v.sendTokenForm.$touch();
            if (this.$v.sendTokenForm.$anyError) {
                return;
            }
            const random = Math.random().toString(36).substring(2,7);

            const params = {
                "to": this.sendTokenForm.to_address,
                "api_symbol": this.symbol,
                "amount": parseFloat(this.sendTokenForm.amount),
                "request_id": "web_" + random
            }
            
            try{
                const response = await api.apiCall('trx','POST','/withdrawals/create',params);
                
                if((response.status).toUpperCase()=="SUCCESS") {
                    this.$swal({
                        text: this.$t("Withdrawal request received"),
                        icon: 'success',
                        confirmButtonColor: '#33AD4A',
                    })
                    this.$refs['send-modal'].hide();
                    this.sendTokenForm.to_address = "";
                    this.sendTokenForm.amount = "";
                }
            }catch(error){
                this.$swal('Error',error.message,'error');
                console.error(error);
            }
        },

        async requestDeposit(){
            this.$v.requestDepositForm.$touch();
            if (this.$v.requestDepositForm.$anyError) {
                return;
            }

            const random = Math.random().toString(36).substring(2,7);

            const params = {
                "api_symbol": this.symbol,
                "amount": parseFloat(this.requestDepositForm.amount),
                "deposit_id": "web_" + random
            }

            try{
                const response = await api.apiCall('trx','POST','/deposit-request/create-request',params);
                
                if((response.status).toUpperCase()=="SUCCESS") {
                    this.$swal({
                        text: this.$t("Deposit request successfully"),
                        icon: 'success',
                        confirmButtonColor: '#33AD4A',
                    });
                    
                    this.$refs['request-deposit-modal'].hide();
                    this.requestDepositForm.amount = "";
                    this.fetchRequestDeposits();
                }
            }catch(error){
                this.$swal('Error',error.message,'error');
                console.error(error);
            }
            
        },

        showSendModal() {
            this.$refs['send-modal'].show()
        },

        showReceiveModal() {
            this.$refs['request-deposit-modal'].show();
            this.receiveModal_address = this.token_wallet[0].address;
        },

        showAddChildModal() {
            this.$refs['add-child-modal'].show()
        },

        async fetchRequestDeposits(){
            try{
                const response = await api.apiCall('trx','GET',`/deposit-request/get-all`,{
                    order:'DESC',
                    page:this.currentPage,
                    take:10,
                    api_symbol:this.balance.api_symbol                  
                });
                this.request_deposit_histories = response.message.data;
                this.rows = response.message.meta.itemCount;
                this.perPage = 10

                this.isLoading = false;
            }catch(error){
                console.error(error);
            }
        },

        async fetchBalances() {
            try{
                const response = await api.apiCall('trx','GET',`/deposit-summary/${this.symbol}`);
                this.balance = {
                    balance: response.message.balance,
                    nettworth: response.message.networth,
                    token: response.message.token,
                    token_image: response.message.token_image,
                    withdrawable: response.message.withdrawable,
                    api_symbol: response.message.api_symbol
                }
            }catch(error){
                console.log(error);
            }
        },

        convertDate(date){
            return moment(date).format('DD-MM-YYYY hh:mm:ss')
        },

        goToRequestDepositDetails(id){
            console.log(id);
            this.$router.push({name:'DepositDetail', params:{symbol:this.symbol, platformId:localStorage.getItem('platform_id'), id:id}});
        }
    }
});
</script>

<style scoped>
@import '../css/styles.css';

.balance-body {
    margin: 0% 17%;
    height: 100%;
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.balance-body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.balance-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.top-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: 0% 10%; */
    margin-top: 4rem;
}

.bal-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.total-balance {
    color: var(--info);
    font-size: 38px;
} 

.balance-token-text1 {
    font-size: 18px;
}

.balance-token-text2 {
    font-size: 13px;
}

.bal-btn {
    background-color: transparent;
    /* min-width: 100px; */
    width: 30%;
    border-radius: 30px;
    border: 2px solid var(--primary);
    color: var(--primary);
    font-weight: bold;
    cursor: pointer;
    /* margin-left: 2rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
}

.bal-btn:hover, .bal-btn:active, .bal-btn:focus {
    color: var(--primary) !important;
    fill: var(--primary) !important;
    border: 2px solid var(--primary) !important;
    background-color: var(--primary-10) !important;
    /* box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px; */
}

.bal-btn #swap {
    fill: var(--primary);  
    padding-left: 0.8rem;
}

.bal-btn div {
    padding-left: 1rem;
}

.bal-btn img {
    width: 30px;
}

/* Child Address Table */
.add-child-btn {
    background-color: var(--primary);
    /* border: none; */
    border-radius: 20px;
    padding: 0px 20px 0 40px;
    /* width: 100px; */
    color: var(--light);
    border: 1px solid var(--primary);
    height: 25px;
    /* padding-left: 45px; */
    position: relative;
    min-width: fit-content;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.add-child-btn:before {
    content: "";
    display: block;
    background-color: var(--primary);
    border-radius: 100%;
    width: 35px;
    height: 35px;
    left: -1px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
    bottom: -6px;
    position: absolute;
}

#plus-icon {
    position: absolute;
    left: 6px;
    top: -1px;
    color: white;
}

.add-child-btn:hover, .add-child-btn:focus, .add-child-btn:hover text {
    color: var(--primary);
    background-color: var(--light);
    fill: var(--light);
    border: 1px solid var(--primary);
}

/* .search-input {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
}

.search-input input {
    border-radius: 20px;
    padding: 0px 15px;
    height: 25px;
}

.search-input:after {
    content: "";
    display: block;
    background-color: white;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
    bottom: -6px;
    right: 0;
    position: absolute;
} */

.search-icon {
    position: absolute;
    z-index: 2;
    right: 7px;
}

#child-add-table tbody::before {
    content: '';
    display: block;
    height: 10px;
}

#child-add-table th {
    font-size: 14px;
    text-align: center;
}

#child-add-table tr {
    background-color: var(--light);
    color: black;
    text-align: center;
}

#child-add-table td {
    vertical-align: middle;
}

#child-add-table tr:first-child td:first-child { border-radius: 30px 0px 0px 30px; }
#child-add-table tr:first-child td:last-child { border-radius: 0px 30px 30px 0px; }

.child-table-th {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* #add-child-form input {
    background-color: var(--info-10);
    border: none;
    color: var(--info);
    text-align: center;
    border-radius: 30px;
    width: 100%;
} */

.icon-btn {
    padding: 0px;
}

.balance-token {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35%;
}

.balance-withdrawal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.deposit-balance {
    display: flex;
    flex-direction: row;
    width: 65%;
    justify-content: space-between;
}

.deposit-balance>div {
    width: 45%;
}

.balance-title {
    font-size: 1rem;
    /* font-weight: bold; */
    color: var(--primary);
    text-align: center;
}

.balance-content {
    font-size: 2rem;
    text-align: center;
    color: var(--info);
}

/* Send Modal */
fieldset {
    color: #555;
    font-family: sans-serif;
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

fieldset::after {
    content: "___  ___  ___  ___  ___  ___";
    display: block;
    position: absolute;
    top: 35px;
    white-space: pre;
    color: white;
}

input#tradingPin {
    position: relative;
    font-size: 16px;
    z-index: 2;
    border: none;
    background: transparent;
    width: 300px;
    text-indent: 27px;
    letter-spacing: 25.6px;
    font-family: Courier;
    margin-left: 55px;
    text-align: left;
}

input#tradingPin:focus {
    outline: none;
}

/* Receive Modal */
#receive-content {
    background: transparent;
    border: none;
}

#receive-content .card-header {
    border-bottom: 1px solid white;
    border-top: 2px solid white;
    background: transparent;
    padding: 0rem 0rem 0.75rem 0rem;
    overflow: hidden;
    overflow-x: scroll;
}

#receive-content .nav {
    flex-wrap: nowrap;
}

#receive-content .nav-item {
    padding-top: 2px;
    margin-left: 5px;
}

#receive-content .nav-item a{
    width: 150px;
    text-align: center;
    border: none;
    color: #bbbbbb;
}

#receive-content .nav-item a:hover {
    border-bottom: 2px solid var(--primary);
    color: var(--primary);
}

#receive-content .nav-item .active {
    border-bottom: 2px solid var(--primary);
    color: var(--primary);
    background: transparent;
}

.modal-wallet-address {
    color: #707070;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.withdraw {
    color: var(--danger);
}

.table-responsive {
    display: inline-table;
}

@media only screen and (max-width:768px) {
    .balance-token {
        width: 100%;
        justify-content: center;
        margin-bottom: 1.5rem;
    }

    .balance-withdrawal {
        flex-direction: column;
    }

    .deposit-balance {
        width: 100%;
    }

    .table-responsive {
        display: block;
    }

    .balance-body {
        margin: 5%;
    }

    .total-balance {
        font-size: 30px;
    }

    .search-input {
        margin-left: 1rem;
    }

    .bal-button {
        flex-direction: column;
    }

    .bal-btn {
        width: 100%;
        margin-bottom: 1rem;
    }
}
</style>